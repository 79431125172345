<template>
  <v-app>
    <demo-drawer />

    <!--    <v-navigation-drawer app right color="white" floating>
      <router-view name="rightDrawer"> </router-view>
    </v-navigation-drawer>-->
    <v-app-bar
      flat
      app
      height="80px"
      outlined
      clipped-left
      style="border-left: none; border-right: none; border-top: none;"
    >
      <router-link class="custom-link" :to="{ name: 'DemoDashboard' }">
        <img
          src="@/assets/logo_rsw.png"
          height="50"
          :alt="settings.name"
          class="mr-10"
        />
      </router-link>
      <div class="hidden-sm-and-down">
        <v-toolbar-title class="text-h5 font-weight-medium">
          {{
            $route.meta.barTitle
              ? $route.meta.barTitle
              : "TaxConsultingServices"
          }}
        </v-toolbar-title>
        <div>
          <span class="primary--text text--darken-3 font-weight-medium">
            {{ $moment().format("dddd") }},
          </span>
          <span class="font-weight-medium grey--text text--darken-2">
            {{ $moment().format("Do MMM YY") }}
          </span>
        </div>
      </div>
      <v-spacer />
      <!--      <v-btn icon class="mr-2">
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>-->
      <v-avatar class="white--text" color="primary">
        <img src="@/assets/lothar.jpg" alt="Profilbild" />
      </v-avatar>
      <v-menu bottom transition="slide-y-transition" open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            {{ userDetails.name_reverse }}
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item
            link
            target="_blank"
            href="https://media.giphy.com/media/3vg4WMhfpIWE4yKKrM/giphy.gif"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Ausloggen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <router-view name="appBarExtension" />
    </v-app-bar>
    <v-main class="white" style="min-height: 100%;">
      <v-slide-y-transition>
        <v-system-bar
          key="systemBarActive"
          color="#f5f5f5"
          v-if="breadCrumbs && breadCrumbs.length > 0"
          style="position: absolute; width: 100%; min-height: 30px; height: auto;"
        >
          <!-- Breadcrumbs -->
          <v-breadcrumbs :items="breadCrumbs" large class="pa-2">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled ? item.disabled : false"
                exact
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-system-bar>
        <v-system-bar
          style="position: absolute; width: 100%; min-height: 30px; height: auto;"
          v-else
          key="systemBarInactive"
          color="transparent"
          height="30"
        />
      </v-slide-y-transition>
      <v-container fluid class="px-lg-16 pt-11 mt-2" style="min-height: 100%">
        <v-fade-transition>
          <router-view name="main" />
        </v-fade-transition>
      </v-container>
      <vue-snotify></vue-snotify>
    </v-main>
    <v-footer
      class="px-lg-16"
      dark
      style="position: relative; height: auto; overflow: visible;"
    >
      <div
        class="pl-lg-14 py-4 d-flex justify-space-between align-center"
        style="width: 100%"
      >
        <div>
          <div class="mb-3">Powered by</div>
          <a href="https://institut-ida.de" target="_blank">
            <img
              src="@/assets/ida_lowres.png"
              alt="IDA - Institut für digitales Management"
              style="max-height: 60px"
            />
          </a>
        </div>
        <div class="text-right">
          <div>
            <span v-html="settings.name" /> <br />
            {{ settings.address1 }} <br />
            {{ settings.zip }} {{ settings.city }}
          </div>
          <div class="mt-4" v-if="settings.email">
            {{ settings.email }}
          </div>
          <div v-if="settings.phone">
            {{ settings.phone }}
          </div>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { adjustedColor } from "@/helpers/adjustColor";

export default {
  name: "Wrapper",
  data: () => ({}),
  computed: {
    brandUrl() {
      return this.$store.getters["state/brandUrl"];
    },
    settings() {
      return this.$store.getters["state/settings"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    },
    breadCrumbs() {
      return this.$store.getters["application/breadCrumbs"];
    }
  },
  created() {
    this.$vuetify.theme.themes.light.primary = "#0D5AA3";
    this.$vuetify.theme.themes.light.secondary = "#D2DDF1";
  },
  methods: {
    logout() {
      this.$store
        .dispatch("state/logout")
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(() => {
          this.$snotify.error("Abmeldung fehlgeschlagen.");
        });
    }
  }
};
</script>

<style scoped>
.theme--light.v-application {
  background-color: #f5f5f5 !important;
}
.v-application--wrap {
  background-color: #f5f5f5 !important;
}
</style>
