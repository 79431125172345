<template>
  <div>
    <div>
      <div class="text-h5">
        4. Verfahren und Maßnahmen
      </div>
      <div class="text-h6 mb-2">
        4.1 Belege, die in Papierform oder digitaler Form vorliegen
      </div>
      <div class="text-h6 mb-4 mt-8">
        4.1.1 Posteingang und Vorsortierung von Papierbelegen
      </div>

      <div class="body-1">
        1. Der papierhafte Posteingang wird unter Beachtung der Vollständigkeit
        (kein Verlust von eingegangenen Sendungen, keine ungeprüfte Vernichtung)
        vom zuständigen Mitarbeiter geöffnet, gesichtet und nach den
        unternehmensinternen Vorgaben
      </div>
      <v-alert
        class="mt-2"
        v-if="entrance === null && !isReleased"
        type="warning"
        text
        dense
      >
        Noch kein Wert ausgewählt
      </v-alert>
      <v-select
        v-model="entrance"
        class="mt-2"
        :items="[
          { value: null, text: 'Offen' },
          'mit einem Posteingangsstempel versehen',
          'nicht mit einem Posteingangsstempel versehen'
        ]"
        filled
        :disabled="isReleased"
      />

      <div class="text-h6 mb-4 mt-8">
        4.1.2 Posteingang und Vorsortierung von digitalen Belegen
      </div>

      <div class="body-1 mb-2">
        1. Der Eingang digitaler Unterlagen wird unter Beachtung der
        Vollständigkeit vom zuständigen Mitarbeiter geöffnet, gesichtet und nach
        den unternehmensinternen Vorgaben an einem dafür vorgesehenen Ort
        gespeichert:
      </div>

      <div v-for="(iPlace, index) in places" :key="index">
        <v-row class="mt-1">
          <v-col cols="12" md="5">
            <v-menu
              v-model="iPlace.menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="isReleased"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="iPlace.date"
                  label="Datum"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  filled
                  dense
                  hide-details
                  :disabled="isReleased"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="iPlace.date"
                @input="iPlace.menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="7">
            <v-text-field
              filled
              hide-details
              dense
              v-model="iPlace.text"
              label="Ort"
              :disabled="isReleased"
            />
          </v-col>
        </v-row>
      </div>

      <v-row justify="end" v-if="!isReleased">
        <v-col cols="12" md="auto">
          <v-btn block depressed @click="addPlace">
            Weiteren Ort hinzufügen
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isReleased: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    entrance: null,
    place: {
      date: "",
      text: "",
      menu: false
    },
    places: [
      {
        date: "2021-02-01",
        text: "Ablageort für papierhafte Eingangspost.",
        menu: false
      },
      {
        date: "2021-02-01",
        text: "Ablageort für nicht-papierhafte Eingangspost.",
        menu: false
      }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    addPlace() {
      this.places.push(this.place);
    }
  }
};
</script>

<style scoped></style>
