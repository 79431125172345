export default {
  isLoggedIn: false,
  permissions: [],
  roles: [],
  userDetails: {
    name_reverse: "Lothar Matthäus"
  },
  brandUrl: "",
  settings: {
    name: "RSW Steuerberatungsgesellschaft mbH",
    address1: "Heganger 14",
    zip: "D-96103",
    city: "Hallstadt",

    email: "info@rsw.ag",
    phone: "+49-951 - 9 15 15 0"
  }
};
