import DemoWrapper from "@/views/demo/Wrapper";

import Middleware from "@/middleware/index";

export default [
  {
    path: "/demo",
    component: DemoWrapper,
    children: [
      {
        path: "",
        name: "DemoDashboard",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "demo-dashboard" */ "@/views/demo/Dashboard.vue"
            )
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest],
          barTitle: "Dashboard"
        }
      },
      {
        path: "analytics",
        name: "DemoAnalytics",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "demo-dashboard" */ "@/views/demo/Analytics.vue"
            )
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest],
          barTitle: "Analytics"
        }
      },
      {
        path: "clients",
        name: "DemoClientIndex",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "demo-dashboard" */ "@/views/demo/ClientIndex.vue"
            )
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest],
          barTitle: "Mandantenübersicht"
        }
      },
      {
        path: "clients/show",
        name: "DemoClientShow",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "demo-dashboard" */ "@/views/demo/ClientShow.vue"
            )
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest],
          barTitle: "Mandantenansicht"
        }
      },
      {
        path: "process-documentation/form",
        name: "DemoProcessDocumentationForm",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "demo-dashboard" */ "@/views/demo/ProcessDocumentationForm.vue"
            )
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest],
          barTitle: "Verfahrensdokumentation"
        }
      }
    ]
  }
];
