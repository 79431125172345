<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card height="100%">
          <v-card-title>
            <div>
              <img
                src="@/assets/dosen_weltmeister.png"
                alt="Dosenweltmeister"
                height="60"
                class="mb-4"
              />
              <div class="text-h5">{{ company.name }}</div>
            </div>
          </v-card-title>
          <v-card-text>
            {{ company.description }}
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn depressed>
              Bearbeiten <v-icon right>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%">
          <v-card-text>
            <div class="text-overline">
              Kontakt
            </div>
          </v-card-text>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-phone-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ company.phone }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Telefon
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ company.email }}
              </v-list-item-title>
              <v-list-item-subtitle>
                E-Mail
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-map-marker-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ company.address }}
              </v-list-item-title>
              <v-list-item-title>
                {{ company.zip }} {{ company.city }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Adresse
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {
    company() {
      return this.$store.getters["demo/company"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
