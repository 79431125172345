<template>
  <v-navigation-drawer
    color="white"
    app
    expand-on-hover
    mobile-breakpoint="0"
    clipped
    width="300px"
  >
    <v-list nav rounded>
      <v-list-item
        class="mt-2"
        link
        :to="{ name: 'DemoDashboard' }"
        exact
        active-class="primary--text"
      >
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="mt-2"
        link
        :to="{ name: item.to }"
        exact
        v-for="(item, index) in items"
        active-class="primary--text"
        :key="index"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {},
  data: () => ({
    items: [
      {
        name: "Mandantenübersicht",
        description:
          "Finden Sie schnell und einfach heraus, welcher Ihrer Klienten noch keine Verfahrensdokumentation abgelegt hat.",
        img: "",
        to: "DemoClientIndex",
        icon: "mdi-account-group-outline"
      },
      {
        name: "Mandantenansicht",
        description:
          "Überprüfen Sie die Daten und Verfahrensdokumentationen Ihres Mandanten.",
        img: "",
        to: "DemoClientShow",
        icon: "mdi-account-outline"
      },
      {
        name: "Verfahrensdokumentation",
        description:
          "Legen Sie eine neue Verfahrensdokumentation an und geben Sie diese im Anschluss GoBD-konform frei.",
        img: "",
        to: "DemoProcessDocumentationForm",
        icon: "mdi-text-box-outline"
      },
      {
        name: "Analytics",
        description:
          "Werten Sie die Daten aus vergangenen Verfahrensdokumentationen aus und erfahren Sie schnell, wo es Potential gibt.",
        img: "",
        to: "DemoAnalytics",
        icon: "mdi-chart-timeline-variant"
      }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
