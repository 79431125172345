export default {
  company: {
    name: "Dosen Weltmeister GmbH",
    description:
      "Die Dosen Weltmeister GmbH ist eine GmbH, welche als Dosen-Weltmeister gekürt wurde, da sie meisterhaft Dosen in die ganze Welt verkauft.",
    phone: "01234-456789",
    email: "hello@dosen-weltmeister.de",
    address: "Weltmeister Straße 2",
    zip: "DE-12345",
    city: "Dosenstadt",

    contactPersons: [
      {
        first_name: "Janina",
        last_name: "Abendroth",
        email: "janina@dosen-weltmeister.de",
        phone: "01234-456789-1"
      },
      {
        first_name: "Ursula",
        last_name: "Baumgartner",
        email: "ursula@dosen-weltmeister.de",
        phone: "01234-456789-2"
      }
    ],

    documentations: [
      {
        created_at: "2020-05-20",
        created_text: "20.05.2020",
        consultant: "Lothar Matthäus (10)",
        contactPerson: "Janina Abendroth"
      }
    ]
  }
};
