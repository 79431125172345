var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"text-h5"},[_vm._v(" 4. Verfahren und Maßnahmen ")]),_c('div',{staticClass:"text-h6 mb-2"},[_vm._v(" 4.1 Belege, die in Papierform oder digitaler Form vorliegen ")]),_c('div',{staticClass:"text-h6 mb-4 mt-8"},[_vm._v(" 4.1.1 Posteingang und Vorsortierung von Papierbelegen ")]),_c('div',{staticClass:"body-1"},[_vm._v(" 1. Der papierhafte Posteingang wird unter Beachtung der Vollständigkeit (kein Verlust von eingegangenen Sendungen, keine ungeprüfte Vernichtung) vom zuständigen Mitarbeiter geöffnet, gesichtet und nach den unternehmensinternen Vorgaben ")]),(_vm.entrance === null && !_vm.isReleased)?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"warning","text":"","dense":""}},[_vm._v(" Noch kein Wert ausgewählt ")]):_vm._e(),_c('v-select',{staticClass:"mt-2",attrs:{"items":[
        { value: null, text: 'Offen' },
        'mit einem Posteingangsstempel versehen',
        'nicht mit einem Posteingangsstempel versehen'
      ],"filled":"","disabled":_vm.isReleased},model:{value:(_vm.entrance),callback:function ($$v) {_vm.entrance=$$v},expression:"entrance"}}),_c('div',{staticClass:"text-h6 mb-4 mt-8"},[_vm._v(" 4.1.2 Posteingang und Vorsortierung von digitalen Belegen ")]),_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" 1. Der Eingang digitaler Unterlagen wird unter Beachtung der Vollständigkeit vom zuständigen Mitarbeiter geöffnet, gesichtet und nach den unternehmensinternen Vorgaben an einem dafür vorgesehenen Ort gespeichert: ")]),_vm._l((_vm.places),function(iPlace,index){return _c('div',{key:index},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.isReleased},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","prepend-inner-icon":"mdi-calendar","readonly":"","filled":"","dense":"","hide-details":"","disabled":_vm.isReleased},model:{value:(iPlace.date),callback:function ($$v) {_vm.$set(iPlace, "date", $$v)},expression:"iPlace.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(iPlace.menu),callback:function ($$v) {_vm.$set(iPlace, "menu", $$v)},expression:"iPlace.menu"}},[_c('v-date-picker',{on:{"input":function($event){iPlace.menu = false}},model:{value:(iPlace.date),callback:function ($$v) {_vm.$set(iPlace, "date", $$v)},expression:"iPlace.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"filled":"","hide-details":"","dense":"","label":"Ort","disabled":_vm.isReleased},model:{value:(iPlace.text),callback:function ($$v) {_vm.$set(iPlace, "text", $$v)},expression:"iPlace.text"}})],1)],1)],1)}),(!_vm.isReleased)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"block":"","depressed":""},on:{"click":_vm.addPlace}},[_vm._v(" Weiteren Ort hinzufügen "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }