<template>
  <div>Base</div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
