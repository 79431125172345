import Vue from "vue";
import Vuex from "vuex";

/**
 * modules
 */
import state from "./state";

import demo from "./demo";
/**
 * model modules
 */

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { state, demo }
});
