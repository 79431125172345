// eslint-disable-next-line no-unused-vars
import store from "../store";

// eslint-disable-next-line no-unused-vars
export default function guest({ next, router }) {
  /*if (localStorage.getItem("token")) {
    store
      .dispatch("state/userState")
      .then(() => {
        return router.push({ name: "Dashboard" });
      })
      .catch(e => {
        if (e.response.status === 401) {
          store
            .dispatch("state/logout")
            .then(() => {
              router.push({ name: "Home" });
            })
            .catch(() => {
              router.push({ name: "Home" });
            });
        }
      });
  } else {
    store.dispatch("state/state").then(() => {
      return next();
    });
  }*/
  return next();
}
