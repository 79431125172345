<template>
  <div
    class="d-flex justify-center align-center flex-column"
    style="width: 100%"
  >
    <div class="text-h2">Willkommen bei TaxConsultantServices!</div>
    <v-btn class="primary mt-4" x-large :to="{ name: 'DemoDashboard' }">
      Zur Demo
      <v-icon right color="white">
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>
