import Vue from "vue";
import VueRouter from "vue-router";
import NoAuthWrapper from "../views/noauth/Wrapper";

import Middleware from "../middleware/index";
import Home from "@/views/noauth/Home";

Vue.use(VueRouter);

/*
 * Routes
 */

import demoRoutes from "./demo/index";

const routes = [
  ...demoRoutes,
  {
    path: "",
    component: NoAuthWrapper,
    children: [
      {
        path: "",
        name: "Home",
        components: {
          main: Home
        },
        meta: {
          nav: false,
          middleware: [Middleware.guest]
        }
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "",
    name: "AuthWrapper",
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/Wrapper"),
    children: []
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

function nextCheck(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMidd = nextCheck(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextMidd });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const ctx = {
      from,
      next,
      router,
      to
    };

    const nextMiddleware = nextCheck(ctx, middleware, 1);

    return middleware[0]({ ...ctx, next: nextMiddleware });
  }

  return next();
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " • TaxConsultantServices"
      : to.meta.barTitle
      ? to.meta.barTitle + " • TaxConsultantServices"
      : "TaxConsultantServices";
  });
});

export default router;
