import hasPermissions from "../helpers/hasPermissions";
import hasAnyPermissions from "../helpers/hasAnyPermissions";
import hasRoles from "../helpers/hasRoles";
import hasAnyRoles from "../helpers/hasAnyRoles";

import store from "../store";

export default function auth({ next, to, router }) {
  // clear breadcrumbs -> TODO: clean solution
  store.commit("application/setBreadCrumbs", []);
  store
    .dispatch("state/userState")
    .then(() => {
      const requiredPermissions = to.meta.permissions;
      const requiredPermissionsAny = to.meta.permissionsAny;
      const requiredRoles = to.meta.roles;
      const requiredRolesAny = to.meta.rolesAny;

      if (requiredPermissions) {
        let canEnter = hasPermissions(requiredPermissions);
        if (requiredRoles) {
          canEnter = canEnter && hasRoles(requiredRoles);
        }
        if (canEnter) {
          return next();
        }
      } else if (requiredPermissionsAny) {
        let canEnter = hasAnyPermissions(requiredPermissionsAny);

        if (requiredRoles) {
          canEnter = canEnter && hasRoles(requiredRoles);
        } else if (requiredRolesAny) {
          canEnter = canEnter && hasAnyRoles(requiredRolesAny);
        }
        if (canEnter) {
          return next();
        }
      } else if (requiredRoles) {
        console.log("roles defined");
        let canEnter = hasRoles(requiredRoles);
        if (canEnter) {
          return next();
        }
      } else if (
        !requiredPermissions &&
        !requiredPermissionsAny &&
        !requiredRoles
      ) {
        return next();
      }
      return router
        .push({ name: "Dashboard", query: { permission: true } })
        .catch(() => {});
    })
    .catch(() => {
      store
        .dispatch("state/logout")
        .then(() => {
          router.push({
            name: "Login",
            query: {
              refererNoAuth: window.location.pathname + window.location.search
            }
          });
        })
        .catch(() => {
          router.push({
            name: "Login",
            query: {
              refererNoAuth: window.location.pathname + window.location.search
            }
          });
        });
    });
}
